import React, { useState, useEffect } from 'react';
import GridLayout from '../common/GridLayout';
import './HeroSection.css';

const HeroSection = ({ title, description, backgroundImage, mainHero, showCarousel, carouselItems }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  // Automatically move to the next slide every 3 seconds (3000ms)
  useEffect(() => {
    if (showCarousel && carouselItems.length > 1) {
      const interval = setInterval(() => {
        setCurrentSlide((prevSlide) =>
          prevSlide === carouselItems.length - 1 ? 0 : prevSlide + 1
        );
      }, 3000);
      return () => clearInterval(interval); // Cleanup on unmount
    }
  }, [showCarousel, carouselItems.length]);

  const handleSlideClick = (index) => {
    // Assuming slides is an array of slide data
    if (index < 0) {
      setCurrentSlide(carouselItems.length - 1); // Go to the last slide if clicked before the first
    } else if (index >= carouselItems.length) {
      setCurrentSlide(0); // Go to the first slide if clicked after the last
    } else {
      setCurrentSlide(index); // Set to the clicked slide
    }
  };

  return (
    <div className="hero-section" id={mainHero}>
      {showCarousel && carouselItems.length > 0 ? (
        <div className="carousel">
          <div
            className="carousel-slide"
            style={{ backgroundImage: `url(${carouselItems[currentSlide].image})` }}
          >
            <div className="container">

              <GridLayout columns={1} customStyles={{ default: { padding: '4rem 0', textAlign: 'left' }, breakPoints: { 768: { height: "auto" }, 576: { height: "auto" } }}}>
                <div className="carousel-content">
                  <h1>{carouselItems[currentSlide].title}</h1>
                  <p>{carouselItems[currentSlide].description}</p>
                </div>
              </GridLayout>
            </div>
            <div className="carousel-item-btn-wrapper">
              {carouselItems.map((_, index) => (
                <button
                  key={index}
                  className={`carousel-item ${index === currentSlide ? 'active' : ''}`}
                  onClick={() => handleSlideClick(index)}
                >

                </button>
              ))}
            </div>
          </div>



        </div>
      ) : (
        <div className="hero-background" style={{ backgroundImage: `url(${backgroundImage})` }}>
          <div className="container">
            <GridLayout columns={1} customStyles={{ padding: '4rem 0', textAlign: 'left' }}>
              <div className="hero-content">
                <h1>{title}</h1>
                <p>{description}</p>
              </div>
            </GridLayout>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeroSection;
