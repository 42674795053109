import React, { useState, useEffect } from 'react';
import './GridLayout.css';

const GridLayout = ({ children, columns = 3, gap = '1rem', customStyles = {}, breakpoints }) => {
  const [numColumns, setNumColumns] = useState(columns);
  const [gridStyles, setGridStyles] = useState(customStyles);

  useEffect(() => {
    const updateColumns = () => {
      const width = window.innerWidth;

      if (breakpoints) {
        for (const [breakpoint, cols] of Object.entries(breakpoints)) {
          if (width < parseInt(breakpoint)) {
            setNumColumns(cols);
            setGridStyles(customStyles[breakpoint])
            return;
          }
        }
      } else {
        if (width < 576) {
          setNumColumns(1);
          setGridStyles(customStyles[576])
        } else if (width < 768) {
          setNumColumns(2);
          setGridStyles(customStyles[768])
        } else {
          setNumColumns(columns);
        }
      }

      if (customStyles?.breakPoints) {
        for (const [breakpoint, stylesProps] of Object.entries(customStyles?.breakPoints)) {
          if (width < parseInt(breakpoint)) {
            setGridStyles(stylesProps)
            return;
          } else {
            setGridStyles(customStyles?.default)
          }
        }
      }
    };

    updateColumns();
    window.addEventListener('resize', updateColumns);

    return () => {
      window.removeEventListener('resize', updateColumns);
    };
  }, [columns, breakpoints]);

  const layoutStyles = {
    display: 'grid',
    gridTemplateColumns: `repeat(${numColumns}, 1fr)`,
    gap: gap,
    ...gridStyles, // Preserve custom styles from parent component
  };

  return <div style={layoutStyles} className="grid-layout">{children}</div>;
};

export default GridLayout;
