import React from 'react';
import GridLayout from '../common/GridLayout';
import './MissionVision.css';

const MissionVisionSection = ({ mission, vision, id }) => {
  return (
    <section className="mission-vision-section" id={id}>
      <div className="container">
        <GridLayout columns={2} gap="0rem"
          customStyles={{ padding: '0rem', height: "100vh" }}>
          <div className="mission">
            <h2>Our Mission</h2>
            <p>{mission}</p>
          </div>
          <div className="vision">
            <h2>Our Vision</h2>
            <p>{vision}</p>
          </div>
        </GridLayout>
      </div>
    </section>
  );
};

export default MissionVisionSection;
