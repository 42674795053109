import React, { useEffect, useState } from 'react';
import Navbar from './components/sections/Navbar';
import DynamicLayout from './layouts/DynamicLayout';
import "./App.css"

const App = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    // Fetch data from the API or local JSON file
    fetch('/data/layout.json')  // You can replace this with your API endpoint
      .then(response => response.json())
      .then(data => setData(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  if (!data) {
    return <div>Loading...</div>;  // Simple loading state
  }

  return (
    <div className="app-container">
      {/* Navbar */}
      <Navbar {...data.navbar} />

      {/* Dynamic Layout for other sections */}
      <DynamicLayout layoutData={data.layoutData} />
    </div>
  );
};

export default App;
