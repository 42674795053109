import React, { useState, useEffect } from 'react';
import './Navbar.css';
import Logo from './CustomComponents/Logo';

const Navbar = ({ logo, links }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isNavbarVisible, setNavbarVisible] = useState(false); // State to track if navbar should be visible

  // Function to toggle the hamburger menu on mobile
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  // Update the mobile state based on window resize
  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
    if (window.innerWidth >= 768) {
      setMenuOpen(false); // Ensure the menu is closed on desktop resize
    }
  };

  // Handle scroll to show/hide navbar with fade effect
  const handleScroll = () => {
    if (window.scrollY > 50) {
      setNavbarVisible(true); // Show navbar if scrolled down
    } else {
      setNavbarVisible(false); // Hide navbar if at the top
    }
  };

  // Add event listener to handle window resize and scroll
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    // Cleanup event listeners on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className={`navbar ${isNavbarVisible ? 'navbar-visible' : ''}`}>
      <div className="container">
        {/* Logo component */}
        <Logo
          title={logo.title}
          path={logo.path}
          logoClass={logo.logoClass}
          wrapperClass={logo.wrapperClass}
        />

        {/* Hamburger Icon for mobile (only if links >= 4 and screen width is mobile) */}
        {isMobile && links.length >= 4 && (
          <div className="hamburger-menu" onClick={toggleMenu}>
            <span className="hamburger-icon">&#9776;</span>
          </div>
        )}

        {/* Links container */}
        <div className={`navbar-links ${isMobile && !isMenuOpen ? 'hidden' : 'open'}`}>
          {links.map((link, index) => (
            <a key={index} href={link.href} className="navbar-link">
              {link.text}
            </a>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
