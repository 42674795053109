import React, { useEffect } from 'react';

const MapComponent = ({ latitude, longitude, zoom, apiKey }) => {
  useEffect(() => {
    const initMap = () => {
      const location = { lat: latitude, lng: longitude }; // Use props for location

      const map = new window.google.maps.Map(document.getElementById('map'), {
        zoom: zoom,
        center: location,
      });

      // Add marker at the custom location
      new window.google.maps.Marker({
        position: location,
        map: map,
      });
    };

    const loadGoogleMapsApi = () => {
      // If the API is already loaded, initialize the map directly
      if (window.google) {
        initMap();
        return;
      }

      // Otherwise, dynamically add the Google Maps API script with the provided API key
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=initMap`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);

      // Attach the initMap function to window so the Google Maps API can invoke it
      window.initMap = initMap;
    };

    // Load the Google Maps API
    loadGoogleMapsApi();

    // Cleanup: Remove the script when the component unmounts to prevent reloading on subsequent renders
    return () => {
      const script = document.querySelector(`script[src="https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=initMap"]`);
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, [latitude, longitude, zoom, apiKey]);

  return <div id="map" style={{ width: '100%', height: '100%' }}></div>;
};

export default MapComponent;
