import React from 'react';
import GridLayout from '../common/GridLayout';
import './Clients.css';

const ClientsSection = ({ title, clients, backgroundImage, showAside, asideContent, id }) => {
  return (
    <section className="clients-section" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }} id={id}>
      <div className="container">
        <GridLayout columns={2} gap="0rem" customStyles={{ padding: '0rem', height: '100vh', width: '100%' }} breakpoints={{ 768: 1, 576: 1 }}>
          {/* Left Section (Clients Content) */}
          <div className="client-content">
            <h2>{title}</h2>
            <div className="client-logo-wrapper">
              <GridLayout columns={4} gap="2rem" customStyles={{ padding: '0rem' }} breakpoints={{ 768: 3, 576: 3 }}>
                {clients.map((client, index) => (
                  <img key={index} src={client.logo} alt={client.name} className="client-logo" />
                ))}
              </GridLayout>

            </div>

          </div>

          {showAside && (
            <aside className="client-aside">
              <p className="company-quote">"{asideContent.quote}"</p>
            </aside>
          )}
        </GridLayout>
      </div>
    </section>
  );
};

export default ClientsSection;
