import React, { useState } from 'react';
import GridLayout from '../common/GridLayout';
import './Products.css';

const ProductsSection = ({ categories, backgroundImage, id }) => {
  const [activeCategory, setActiveCategory] = useState(0); // Manage active category state
  const [activeProduct, setActiveProduct] = useState(0); // Manage active product state

  const handleCategoryClick = (index) => {
    setActiveCategory(index);
    setActiveProduct(0); // Reset active product when category changes
  };

  const handleProductClick = (index) => {
    setActiveProduct(index);
  };

  return (
    <section className="products-section" id={id}>
      <div
        className="products-hero"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="container">
          <h1>Our Products</h1>
          <div className="product-tabs">
            {categories.map((category, index) => (
              <button
                key={index}
                className={`product-tab ${index === activeCategory ? 'active' : ''}`}
                onClick={() => handleCategoryClick(index)}
              >
                {category.name}
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className="container">
        <div className="product-wrapper">
          <div className={`product-content ${activeCategory}`}>
            <div className="product-selector-wrapper">
              <div className="product-selector">
                {categories[activeCategory].products.map((product, index) => (
                  <button
                    key={index}
                    className={`product-select ${index === activeProduct ? 'active' : ''}`}
                    onClick={() => handleProductClick(index)}
                  >
                    {product.name}
                  </button>
                ))}
              </div>

            </div>
            <div className="gridWrapper">
              <GridLayout
                columns={2}
                gap="2rem"
                customStyles={{ padding: '0rem', alignItems: 'center' }}
                breakpoints={{
                  576: 1,
                  768: 1,
                  992: 2,
                  1200: 2,
                  1368: 2,
                  1600: 2,
                  1920: 2,
                }}
              >
                <div className="product-image">
                  <img
                    src={categories[activeCategory].products[activeProduct].image}
                    alt={categories[activeCategory].products[activeProduct].name}
                  />
                  <div className="subtext">{categories[activeCategory].products[activeProduct].subtext}</div>
                </div>
                <div className="product-details">
                  <h2>{categories[activeCategory].products[activeProduct].name}</h2>
                  <p>{categories[activeCategory].products[activeProduct].description}</p>
                </div>

              </GridLayout>
            </div>

          </div>

        </div>
      </div>
    </section>
  );
};

export default ProductsSection;
