import React from 'react';
import GridLayout from '../common/GridLayout';
import './Contact.css';
import MapComponent from './CustomComponents/MapComponent';

const ContactSection = ({ contactDetails, mapProps, id }) => {
  return (
    <section className="contact-section" id={id}>
      <div className="container">
        <h2>Get in Touch</h2>
        <GridLayout columns={2} gap="10rem" customStyles={{ default: { padding: '0rem' }, breakPoints: { 768: { gap: "5rem!important" }, 576: { gap: "5rem!important" } } }} breakpoints={{ 768: 1 }}>
          {/* Left: Contact Information */}
          <div className="contact-details">
            <p>{contactDetails.description}</p>
            <ul>
              <li>
                <i className="fas fa-phone-alt contact-icon"></i>
                <div className="d-flex flex-direction-column">
                  {Array.isArray(contactDetails.phone) ? contactDetails.phone.map((phone) => (
                    <div key={phone}>
                      {phone}
                    </div>
                  )) : contactDetails.phone}
                </div>
              </li>
              <li>
                <i className="fas fa-envelope contact-icon"></i>
                <div className="d-flex flex-direction-column">
                  {Array.isArray(contactDetails.email) ? contactDetails.email.map((email) => (
                    <div key={email}>
                      {email}
                    </div>
                  )) : contactDetails.email}
                </div>
              </li>
              <li>
                <i className="fas fa-map-marker-alt contact-icon"></i>
                {contactDetails.address}
              </li>
            </ul>
          </div>

          {/* Right: Map Component */}
          <div className="contact-map">
            <MapComponent {...mapProps} />
          </div>
        </GridLayout>
      </div>
    </section>
  );
};

export default ContactSection;
