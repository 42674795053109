import HeroSection from '../components/sections/HeroSection';
import AboutUs from '../components/sections/AboutUs';
import Products from '../components/sections/Products';
import MissionVision from '../components/sections/MissionVision';
import Clients from '../components/sections/Clients';
import Contact from '../components/sections/Contact';
import Footer from '../components/sections/Footer';

const componentsMap = {
  HeroSection,
  AboutUs,
  Products,
  MissionVision,
  Clients,
  Contact,
  Footer,
};

export default componentsMap;
