import React from 'react';
import componentsMap from '../utils/componentsMap';

const DynamicLayout = ({ layoutData }) => {
  return (
    <div>
      {layoutData.map((section, index) => {
        const Component = componentsMap[section.type];
        if (Component) {
          return (
            <Component key={index} {...section.props} />
          );
        }
        return <div key={index}>Component {section.type} not found.</div>;
      })}
    </div>
  );
};

export default DynamicLayout;
