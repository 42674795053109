import React from 'react';
import './AboutUs.css';
import GridLayout from '../common/GridLayout';
import AsideQuotes from './CustomComponents/AsideQuotes';

const AboutUs = ({ showAside, asideContent, title, content }) => {
  return (
    <section className="about-us-section" id="about">
      <div className="container">
        <GridLayout
          columns={showAside ? 2 : 1} /* Show 2 columns if showAside is true */
          gap="0rem"
          customStyles={{ default: { padding: '0rem', height:"100vh" }, breakPoints: {768: {height: "auto" }, 576: {height: "auto" } }}}
        >
          {/* Left Section (Logo and Tagline) */}
          {showAside && (
            <AsideQuotes
              asideContent={asideContent}
            />
          )}

          {/* Right Section (About Us Content) */}
          <div className="about-content">
            <h2>{title}</h2>
            <p>{content}</p>
          </div>
        </GridLayout>
      </div>

    </section>
  );
};

export default AboutUs;
