import React from 'react'

function Logo({title, path, logoClass, wrapperClass}) {
  return (
    <div className={`${wrapperClass}`}>
      {path && <img src={path} alt="Logo" className={logoClass}/>}
      {title && <h1>{title}</h1>}
    </div>
  )
}

export default Logo
