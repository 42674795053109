import React from 'react';
import Logo from './Logo';

const AsideQuotes = ({ asideContent }) => {
  const { image, tagLine, quote, altText, imgClass, wrapperClass, quoteClass } = asideContent || {}
  return (
    <div className={"aside-text-wrapper"}>
      <aside className={wrapperClass}>
        {image?.type === "logo" && <Logo
          path={image?.path}
          logoClass={imgClass}
        />}
        <div className="aside-content">
          {tagLine && <h3>{tagLine}</h3>}
          {quote && <p className={quoteClass}>"{quote}"</p>}
        </div>
      </aside>
    </div>
  );
};

export default AsideQuotes;
